import {Component, EventEmitter, Inject, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AccountService} from '../../../consultant/services/account.service';
import {ServerErrors} from '../../helpers/server-errors';
import {FormBuilder, Validators} from '@angular/forms';
import {FormHelper} from '../../helpers/form.helper';
import {CookieService} from 'ng2-cookies';
import {NGXLogger} from 'ngx-logger';
import {AuthService} from '../../../../services/auth.service';
import {BirthDate} from '../../../../entity/birth-date';
import {SubSink} from 'subsink';
import {UserService} from '../../../../services/user.service';
import {AllEmiterService} from '../../../../services/all-emiter.service';
import {SCREEN_SIZE} from '../../../../screen-size.enum';
import {ResizeService} from '../../../../services/resize.service';
import {UtilService} from '../../../../services/util.service';
import {LoginResponse} from '../../../../entity/login-response';
import {WINDOW} from '../../../../providers/window.provider';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-registration-step2',
    templateUrl: './registration-step2.component.html',
    styleUrls: ['./registration-step2.component.scss'],
})
export class RegistrationStep2Component extends ServerErrors implements OnInit, OnDestroy {
    countries;
    months = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'];

    nonValidISOCountries = ['an', 'cs', 'uk'];
    firstName: string;
    lastName: string;
    gender: string;
    dob: BirthDate = new BirthDate();
    private subs = new SubSink();
    doSignIn: EventEmitter<boolean> = new EventEmitter();

    size: SCREEN_SIZE;
    phoneCountryCode: string;

    constructor(
        public bsModalRef: BsModalRef,
        private accountService: AccountService,
        private fb: FormBuilder,
        private authService: AuthService,
        private cookie: CookieService,
        private logger: NGXLogger,
        private bsModalService: BsModalService,
        public userService: UserService,
        private allEmiterService: AllEmiterService,
        private resizeSvc: ResizeService,
        private utilService: UtilService,
        @Inject(WINDOW) private window: Window,
    ) {
        super();
    }

    ngOnInit() {
        this.buildForm();
        this.subs.sink = this.accountService.getCountries(true).subscribe((res) => {
            res = res.filter(item => !this.nonValidISOCountries.includes(item.flag));
            this.countries = res;
        });
        this.firstName = (this.firstName) ? this.firstName : '';
        this.lastName = (this.lastName) ? this.lastName : '';
        this.dob.day = (this.dob.day) ? this.dob.day : 0;
        this.dob.month = (this.dob.month) ? this.dob.month : 0;
        this.dob.year = (this.dob.year) ? this.dob.year : 0;
        this.form.controls['firstName'].setValue(this.firstName);
        this.form.controls['lastName'].setValue(this.lastName);


        this.subs.sink = this.resizeSvc.onResize$.subscribe(x => {
            this.size = x;
        });
    }

    buildForm() {
        let random = null;
        if (environment.test.randomData) {
            random = '1';
        }
        this.form = this.fb.group({
            firstName: [random, Validators.compose([
                Validators.required,
            ])],
            lastName: [random, Validators.compose([
                Validators.required,
            ])],
            birthDateDay: ['', Validators.compose([
                Validators.required,
            ])],
            birthDateMonth: ['', Validators.compose([
                Validators.required,
            ])],
            birthDateYear: ['', Validators.compose([
                Validators.required,
            ])],
            address: [random],
            defaultPhoneCountry: ['', Validators.compose([
                Validators.required,
            ])],
            defaultPhoneNumber: ['', ],
            zip: [random],
            app: [false]
        });
        this.phoneCountryCode = '';
    }

    getDays() {
        const days: number[] = [];
        for (let i = 1; i <= 31; i++) {
            days.push(i);
        }
        return days;
    }

    getYears() {
        const years = [];
        for (let i = 1920; i <= 2001; i++) {
            years.push(i);
        }
        return years.sort((a, b) => {
            return b - a;
        });
    }

    getMonths() {
        return this.months.map(item => item.slice(0, 3));
    }

    getPhoneCode() {
        if (!this.form.controls['defaultPhoneCountry'].value) {
            return;
        }
        return this.getCountry(this.form.controls['defaultPhoneCountry'].value).phoneCode;
    }

    validatePhoneNumber(countryCode: string) {

        countryCode = countryCode.replace('+', '');
        let phoneNumber = this.form.controls['defaultPhoneNumber'].value;
        if (phoneNumber) {
            // Remove + symbols
            phoneNumber = phoneNumber.replace('+', '');
            phoneNumber = phoneNumber.replace(' ', '');
            // If country code is at the begging of the phone number should be remove (64)
            if (phoneNumber.substr(0, countryCode.length) === countryCode) {
                phoneNumber = phoneNumber.substr(countryCode.length);
            }
            // Same previous validation but we add an 0 to the country code (064)
            countryCode = '0' + countryCode;
            if (phoneNumber.substr(0, countryCode.length) === countryCode) {
                phoneNumber = phoneNumber.substr(countryCode.length);
            }
            // Same previous validation but we add an 0 to the country code (0064)
            countryCode = '00' + countryCode;
            if (phoneNumber.substr(0, countryCode.length) === countryCode) {
                phoneNumber = phoneNumber.substr(countryCode.length);
            }
            this.form.controls['defaultPhoneNumber'].setValue(phoneNumber);
        }


    }

    getCountry(id) {
        return this.countries.find(el => el.id === id);
    }

    doSubmit() {
        FormHelper.touchedForm(this.form);
        if (!this.form.valid) {
            return;
        }
        this.form.controls['address'].setValue('');
        this.form.controls['zip'].setValue('');
        const form = {... this.form.value};
        if (!this.form.value.defaultPhoneNumber || this.form.value.defaultPhoneNumber.length === 0 || this.form.value.defaultPhoneNumber === '') {
            delete form.defaultPhoneNumber;
        }
        const self = this;
        this.subs.sink = this.authService.doRegistrationStep2(form).subscribe((res: LoginResponse) => {
            self.utilService.localStorageSetItem('token', res.token);
            // Get details like curency for next steps
            self.subs.sink = this.userService.getDetails().subscribe((userDetails) => {
                self.utilService.localStorageSetItem('userDetails', JSON.stringify(userDetails));
                self.utilService.localStorageSetItem('registration', 'step3');
                self.window.location.replace(self.window.location + '?registration=true');
                self.utilService.localStorageRemoveItem('firstName');
                self.utilService.localStorageRemoveItem('lastName');
            }, (e) => {
                if (e.message !== 'No user data') {
                    throw e;
                }
            });

        }, (res: any) => {
            if (res && res.error && res.error.additionalFields) {
                Object.entries(res.error.additionalFields).forEach(([key, value]) => {
                    this.setServerError(key, value);
                });
            }

        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    phoneCountryCodeChange() {
        this.phoneCountryCode = this.getPhoneCode();
        this.validatePhoneNumber(this.phoneCountryCode);
    }
}
